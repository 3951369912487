<template>
  <div :class="{ 'c-dark-theme': $store.state.darkMode, animated: false, fadeIn: true }">
    <!-- 항공기 소모 데이터 조회 --------------------------------------------------------------------------------------------->

    <BRow class="mt-1 mb-1">
      <BCol cols="3">
        <BInputGroup size="sm" prepend="등록일">
          <BFormInput type="date"
                      v-model="batchDts"
                      @input="getConsumption"
          />
          <BInputGroupAppend>
            <BButton variant="info"
                     size="sm"
                     @click="getConsumption">
              <BIconSearch/> 조회
            </BButton>
          </BInputGroupAppend>
        </BInputGroup>
      </BCol>
      <BCol cols="6"></BCol>
      <BCol class="text-right"  cols="3">
        <BInputGroup size="sm">
          <BFormSelect size="sm" v-model="$store.state.numOfInoutItem" :options="[10,15,20,30,50,100]" style="background-color:#2f303a"/>
          <BInputGroupAppend>
            <BButtonGroup>
              <BButton size="sm" variant="primary" @click="getConsumption"><BIconArrowRepeat/></BButton>
            </BButtonGroup>
          </BInputGroupAppend>
          <BButton variant="warning"
                   size="sm"
                   class="ml-3"
                   @click="showConsumptionModal">
            <BIconCardList/> <b> 소모처리 코드관리</b>
          </BButton>
        </BInputGroup>


      </BCol>
    </BRow>

    <BRow>
      <BCol>

        <vue-excel-editor
          v-model="conRows"
          ref="excelConGrid"
          width="100%"
          class="mb-1"
          :page="$store.state.numOfInoutItem"
          :readonly-style="{backgroundColor:'#4F5040'}"
          @update="updateConCell"
          @select="selectConRecord"
          :localized-label="editorLabel"
          :key="conEditorKey"
          filter-row>
          <vue-excel-column field="_id"                   type="string"   key-field invisible/>
          <vue-excel-column field="sendDt"                type="date"     width="140px"  label="전송일" :to-text="toLocalTime" readonly/>
          <vue-excel-column field="SPPRT_UC"              type="string"   width="140px" label="부대코드" />
          <vue-excel-column field="STRG_UC"               type="string"   width="150px" label="보급대대코드"/>
          <vue-excel-column field="LOTNO"                 type="string"   width="100px" label="로트번호" />
          <vue-excel-column field="PRSC_DATE"	            type="string"   width="100px" label="시효일자" />
          <vue-excel-column field="MFGDT"	                type="string"   width="100px" label="제조일자" />
          <vue-excel-column field="FSC"	                  type="string"   width="120px" label="군급 분류코드" />
          <vue-excel-column field="NIIN"	                type="string"   width="150px" label="국가품목 식별번호" />
          <vue-excel-column field="BDGT_PRJ_CD"	          type="string"   width="250px" label="예산사업 코드" />
          <vue-excel-column field="SOCD"	                type="string"   width="110px" label="보급목적 코드" />
          <vue-excel-column field="INDVD_SN"	            type="string"   width="180px" label="개체일련번호"/>
          <vue-excel-column field="REFL_ISSU_RTNQTY"      type="number"   width="120px" label="급유량(L)"/>
          <vue-excel-column field="RCPNT_SRVNO"	          type="string"   width="150px" label="수령인 군번" />
          <vue-excel-column field="RCPNT_MDCD"            type="string"   width="110px" label="수령인군 구분"/>
          <vue-excel-column field="RMRK"	                type="string"   width="80px" label="비고"/>
          <vue-excel-column field="REFL_VHCLNO"	          type="string"   width="130px" label="급유차량 번호"/>
          <vue-excel-column field="MCCD"                  type="string"   width="120px" label="물품 상태코드"/>
          <vue-excel-column field="STRLOC"	              type="string"   width="160px" label="저장위치"/>
          <vue-excel-column field="UICD"	                type="string"   width="100px"  label="단위코드"/>
          <vue-excel-column field="WHSNO"	                type="string"   width="100px"  label="창고번호" />
          <vue-excel-column field="STRG_FCLTNO"	          type="string"   width="140px"  label="저장시설 번호" />
          <vue-excel-column field="TOC"	                  type="string"   width="140px"  label="거래사유 코드" />
          <vue-excel-column field="ORGNZT_UC"	            type="string"   width="120px"  label="편성부대코드" />
          <vue-excel-column field="RCPT_INDVD_SN"	        type="string"   width="180px"  label="수령장비 개체일련번호" />
          <vue-excel-column field="RCPT_FSC"	            type="string"   width="180px"  label="수령장비 군급분류코드" />
          <vue-excel-column field="RCPT_NIIN"	            type="string"   width="190px"  label="수령장비 국가품목식별번호"/>
          <vue-excel-column field="ISSU_RTNDT"	          type="string"   width="120px"  label="불출반납일자"/>
          <vue-excel-column field="OPERTN_UC"	            type="string"   width="120px" label="운영부대코드"/>

        </vue-excel-editor>

        <BButton block variant="danger" size="sm" style="width:120px"
                 @click="delConRecord"
                 v-if="selConRows.length!==0">
          <BIconTrashFill/> 선택 삭제
        </BButton>


        <p v-if="!conRows.length" class="text-center text-warning">데이터 없음</p>



      </BCol>
    </BRow>


    <BModal id="con-detail"
            :class="{ 'c-dark-theme': true }"
            ref="conDetail"
            body-bg-variant="dark"
            header-bg-variant="primary"
            footer-bg-variant="dark"
            body-text-variant="dark"
            header-text-variant="white"
            footer-text-variant="dark"
            size="xl"
            title="소모처리 코드관리"
            no-close-on-backdrop
            scrollable>

      <BTabs>
        <BTab title="장비코드">
          <EquipmentCode/>
        </BTab>


        <BTab title="항공기">
          <AircraftCode/>
        </BTab>

        <BTab title="급유차">
          <TankLorryCode/>
        </BTab>

        <BTab title="거래사유">
          <TocCode/>
        </BTab>

        <BTab title="고정코드">
          <StaticCode/>
        </BTab>

      </BTabs>

    </BModal>



  </div>


</template>

<style src="spinkit/spinkit.min.css"></style>

<script>

//-------------------------------------------------------------------------------------------------
import '@/common/HelperMixin';

import {
  apiCall, unitSign
} from '@/common/utils';

import moment from "moment";
import con, {ExcelEditorLabel} from '@/common/constants'
import {InoutColMap, ConsumeColMap} from "@/common/schema";
import {IoOpts, IoStsMap, FuelStsOpts} from "@/common/constants";
import TocCode from "@/views/components/deliisCodes/TocCode.vue";
import TankLorryCode from "@/views/components/deliisCodes/TankLorryCode.vue";
import StaticCode from "@/views/components/deliisCodes/StaticCode.vue";
import EquipmentCode from "@/views/components/deliisCodes/EquipmentCode.vue";
import AircraftCode from "@/views/components/deliisCodes/AircraftCode.vue";


// const recvTypeMap = { manual: "매뉴얼", api: "API", socket: "소켓" };


//----------------------------------------------------------------------------------------------------
export default {
  name: "Consumption",
  components: {
    AircraftCode, EquipmentCode, StaticCode, TankLorryCode, TocCode
  },
  data () {
    return {
      graphKey: 0,
      volUnit: this.$store.state.volUnit,
      lenUnit: this.$store.state.lenUnit,
      tmpUnit: this.$store.state.tmpUnit,
      con,
      fromDts: moment().format('YYYY-MM-DD'),
      toDts: moment().format('YYYY-MM-DD'),
      batchDts: moment().format('YYYY-MM-DD'),
      row: null,
      editorKey: 0,
      sts: '',
      arCode: '',
      tanks: [],
      tid: '',
      cols: InoutColMap,
      conCols: ConsumeColMap,
      IoOpts,
      FuelStsOpts,
      IoStsMap,
      tankOpts : [],
      tankCodes: this.$store.state.tanks['codes'],

      tank : null,

      editorLabel: ExcelEditorLabel,
      downloadModalShow: false,
      downloadReason: '',

      progCount: 0,
      totalCount: 0,
      rows: [],
      selectedRows: [],
      selectedInfo: { normal:0, exclude: 0, already:0},

      paramMap: {},
      numOfRecord: 10,
      updateKey: 0,

      modalTitle: '',

      conRows: [],
      selConRows: [],
      conEditorKey: 0,

    }

  },
  async created(){
    try{

      console.log("--- Consumption creating ---------------------");
      await this.getConsumption();

    }catch(err){
      console.error(err);
    }
  },

  computed: {},

  async mounted() {
    // console.log("--- Consumption mounting---------------------");
    // below is not work!
  },

  beforeDestroy(){
    // using "removeListener" here, but this should be whatever $socket provides
    // for removing listeners

  },

  methods: {
    unitSign,

    async selectConRecord(idx, evt){
      try{
        if(!evt){
          this.selConRows = [];
          return;
        }
        this.selConRows = this.$refs['excelConGrid'].getSelectedRecords();
      }catch(err){
        console.error(err)
      }
    },


    showConsumptionModal(){
      this.$refs['conDetail'].show();
    },

    async getConsumption(){
      try{
        this.conRows = [];
        // console.debug( "getConsumption-------->", this.batchDts );
        const rs = await apiCall("GET", `/api/consumption/${this.batchDts}`);
        if(rs.code===200) {
          this.conRows = rs.result;
          await this.toastInfo(`항공기 소모데이터 ${rs.result.length}건 조회 완료`);
        }else{
          await this.toastResult(rs);
        }
        // console.log(rs);

      }catch(err){
        console.error(err);
      }
    },


    async updateConCell(rec){

      let rc = rec.pop();
      if(!rc.keys[0]){
        return;
      }


      // console.log( "updateRecord--------- record.$id--->",rc.$id );
      // console.log( "updateRecord--------- record.keys[0]--->",rc.keys[0] );
      // console.log( "updateRecord--------- rec[0]|rc --->",rc );
      const idx = this.$refs['excelConGrid'].rowIndex[rc.$id];
      // console.log( "updateRecord--------- rowIndex--->",idx );
      // console.log( "updateRecord--------- $refs --->", this.$refs['excelConGrid'] );

      // let row = this.$refs['excelGrid'].table[tIndex];
      //
      // console.log( "row ---------> ", row );

      const cols = this.conCols;
      let r = null;

      const param = {};
      const objectId = rc.keys[0];
      const fieldName = rc.name;
      param[fieldName] = rc.newVal;
      try{
        // console.log( 'old value --->',rc.oldVal, 'new value --->', rc.newVal );

        // console.log( '-----> will be updated to --->', param);
        r = await apiCall("PUT", `/api/consumption/${objectId}`, param);
        // console.log( r );
        if( r.code===200 ) {
          this.rows[idx] = r.result;
          // this.conEditorKey++;
        }

        // console.log( r );
        await this.toastResult(r, `${cols[fieldName]} 수정`, false);
      }catch(err){
        await this.alertDanger(err.message, r.code, 'danger');
        console.error( err );
      }

    },

    async delConRecord(){
      // let r = null, msg = null, notice = null;
      try{

        let params = this.selConRows;
        this.progCount = 0;
        this.totalCount = params.length;
        if(!params.length) {
          return this.toastInfo('[선텍된 레코드 없음] 좌측의 번호를 클릭하여 레코드 선택');
        }

        const confirmMsg = `${params.length} 개의 데이터를 삭제 합니다. 삭제된 데이터는 복구할 수 없습니다. 진행 하시겠습니까?`;

        if( !(await this.confirmModal(confirmMsg, '레코드 삭제')) ){
          return;
        }

        let failCnt = 0;
        for( let record of params){
          // console.log( "deleteRecord for --------->", record );
          const tIndex = this.$refs['excelConGrid'].rowIndex[record.$id];
          const r = await apiCall('DEL', `/api/consumption/${record._id}`);
          // console.log( r );
          if( r.code===200 ){
            this.progCount++;
            this.$refs["excelConGrid"].deleteRecord(tIndex);
          }
        }

        await this.alertModal(`삭제: ${this.progCount}건, 실패: ${failCnt}`, '레코드 삭제결과', 'warning');
        this.$refs['excelConGrid'].clearAllSelected();
        this.selConRows = [];
        await this.getConsumption();

      }catch(err){
        console.error( err );
      }
    },




  }
}
</script>
